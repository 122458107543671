
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

interface edit {
      dateAll: any,
  name: string;
  members: any;
  regions: any;
  note: string;
  leaderId: string;
  leaderName: string;
  
  totalSekolah: number;
  totalPendapatanTerbayar: number;
  totalPendapatanBelumTerbayar: number;
  totalPendapatan: number;
  
  fromMonth: number;
  fromYear: number;
  untilMonth: number;
  untilYear: number;

  showFilter: boolean;
}

interface items {
  area: any;
  user_sales_area: any;
  provinsiOptions: any;
  errorName: string;
  errorMember: string;
  errorRegion: string;
  regions: string;
  members: string;
  disableButton: boolean;
}

interface dataDelete{
  salesId: string;
  salesName: string;
  province: string;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const edit = reactive<edit>({
      dateAll: [],
      name: "",
      members: [],
      regions: [],
      note: "",
      leaderId: "",
      leaderName: "",
      
      totalSekolah: 0,
      totalPendapatanTerbayar: 0,
      totalPendapatanBelumTerbayar: 0,
      totalPendapatan: 0,

      fromMonth: 0,
      fromYear: 2022,
      untilMonth: 0,
      untilYear: 2022,

      showFilter: false,
    });

    let items = reactive<items>({
      area: [],
      user_sales_area: [],
      provinsiOptions: [],
      errorName: "",
      errorMember: "",
      errorRegion: "",
      regions: "",
      members: "",
      disableButton: false,
    });

    let dataDelete = reactive<dataDelete>({
      salesId: "",
      salesName: "",
      province: "",
    });

    // const getId = () => {
    //   ApiService
    //     .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id)
    //     .then((res) => {
    //       edit.name = res.data.name;
    //       edit.members = res.data.members;
    //       edit.regions = res.data.regions;
    //       edit.note = res.data.note;
    //     })
    //     .catch((e) => {
          
    //       toast.error(e.response.data.detail)

    //     });
    // };

    const saveChanges1 = () => {

      if (
        edit.name === "" ||
        !edit.members.length ||
        !edit.regions.length
      ) {
        items.errorName = "Tim Area Wajib Diisi";
        items.errorMember = "Sales Area Wajib Diisi";
        items.errorRegion = "Provinsi Wajib Diisi";
      } else {
        items.errorName = "";
        items.errorMember = "",
        items.errorRegion = "",

        // Activate loading indicator
        // submitButton.value?.setAttribute("data-kt-indicator", "on");

        items.disableButton = true;

        const dataput = {
          name : edit.name,
          leaderId : edit.leaderId,
          leaderName : edit.leaderName,
          members : edit.members,
          regions : getProvinceFromArrayList(edit.regions),
          note : edit.note,
        }

        console.log(dataput);

        ApiService
        .putWithData('crmv2/main_user/sales/team/' + route.params.id, dataput)
        .then((res) => {
          toast.success("Berhasil Update Sales Area");
          router.push({ path: "/superadmin/sales/sales-area" });
        })
        .catch((e) => {
          items.disableButton = false;
          toast.error(e.response.data.detail)
        });
      }
    };

    const combo_user_sales_area = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_AREA"
      ).then((response) => {
        items.user_sales_area = response.data;
      });
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const submitAddProvince = () => {
      if (items.regions == "") {
        toast.error("Silahkan Pilih Provinsi Terlebih Dahulu");
      } else {
        var check = true;
        var saveEdit = true;

        var newArrayRegion = new Array();

        if (edit.regions.length) {
          for(var i = 0; i < edit.members.length; i++){
              if( edit.regions[i] == items.regions){
                toast.error(edit.regions[i] + " Sudah Digunakan");
                check = false;
                saveEdit = false;
              }
          }
          if (check == true) {
            newArrayRegion = getProvinceFromArrayList(edit.regions);
            newArrayRegion.push(items.regions);
            saveEdit = true;
          }
        } else {
          newArrayRegion.push(items.regions);
          saveEdit = true;
        }

          if (saveEdit == true) {
            const dataput = {
              name : edit.name,
              leaderId : edit.leaderId,
              leaderName : edit.leaderName,
              members : edit.members,
              regions : newArrayRegion,
              note : edit.note,
            }

            ApiService
            .putWithData('crmv2/main_user/sales/team/' + route.params.id, dataput)
            .then((res) => {
              toast.success("Berhasil Update Provinsi");
              getId();
              getTeamRegionDetailId();
            })
            .catch((e) => {
              items.disableButton = false;
              toast.error(e.response.data.detail)
              // getId();
              // edit.regions.splice(edit.regions.indexOf(newArrayRegion), 1);
              // getTeamRegionDetailId();
            });
          }
        
      }
    };

    const submitAddSales = () => {
      if (items.members == "") {
        toast.error("Silahkan Pilih Sales Terlebih Dahulu");
      } else {

        var check = true;
        var saveEdit = false;

        var valMember= {};
        for(var i = 0; i < items.user_sales_area.length; i++){
            if( items.user_sales_area[i]._id == items.members){
              valMember= {
                salesId : items.user_sales_area[i]._id,
                salesName : items.user_sales_area[i].name,
              }
            }
        }

        const listEditData = {
            name: edit.name,
            members: edit.members,
            regions: getProvinceFromArrayList(edit.regions),
            note: edit.note,
            leaderId: edit.leaderId,
            leaderName: edit.leaderName,
        };
        
        if (edit.members.length) {

          for(var i = 0; i < edit.members.length; i++){
              if( edit.members[i].salesId == items.members){
                toast.error(edit.members[i].salesName + " Sudah Digunakan");
                check = false;
                saveEdit = false;
              }
          }

          if (check == true) {
            listEditData.members.push(valMember);
            saveEdit = true;
          }
        } else {
          listEditData.members.push(valMember);
          saveEdit = true;
        }

        if (saveEdit == true) {

            ApiService
            .putWithData('crmv2/main_user/sales/team/' + route.params.id, listEditData)
            .then((res) => {
              toast.success("Berhasil Update Sales Area");
              getId();
              getTeamRegionDetailId();
            })
            .catch((e) => {
              items.disableButton = false;
              toast.error(e.response.data.detail)
              // getId();
              edit.members.splice(edit.members.indexOf(valMember), 1);
              getTeamRegionDetailId();
            });
        }
      }
    };

    function handleDeleteArea (index, region) {
      // edit.regions.splice(edit.regions.indexOf(item), 1);
      console.log(edit.regions.length)
      if (edit.regions.length < 2) {
        toast.error("Provinsi tidak boleh kosong")
      } else {
        var area = getProvinceFromArrayList(edit.regions);
        console.log(area)
        var newArrayArea= new Array();
        for(var i = 0; i < area.length; i++){
              if( area[i] != region){              
                newArrayArea.push(area[i]);
              }
        }
        console.log(newArrayArea)

        const listEditData = {
              name: edit.name,
              members: edit.members,
              regions: newArrayArea,
              note: edit.note,
              leaderId: edit.leaderId,
              leaderName: edit.leaderName,
          };
            ApiService
              .putWithData('crmv2/main_user/sales/team/' + route.params.id, listEditData)
              .then((res) => {
                toast.success("Berhasil Delete Area");
                getId();
                getTeamRegionDetailId();
              })
              .catch((e) => {
                items.disableButton = false;
                toast.error(e.response.data.detail)
                getId();
                // edit.members.splice(edit.members.indexOf(valMember), 1);
                getTeamRegionDetailId();
              });
      }
      
    };

    function handleDeleteSales (index, salesId){

      var newArraySalesMember = new Array();
      var valNewMember;
      for(var i = 0; i < edit.members.length; i++){
            if( edit.members[i].salesId != salesId){
              valNewMember= {
                salesId : edit.members[i].salesId,
                salesName : edit.members[i].salesName,
              }

              newArraySalesMember.push(valNewMember);
            }
        }

      const listEditData = {
            name: edit.name,
            members: newArraySalesMember,
            regions: getProvinceFromArrayList(edit.regions),
            note: edit.note,
            leaderId: edit.leaderId,
            leaderName: edit.leaderName,
        };
          ApiService
            .putWithData('crmv2/main_user/sales/team/' + route.params.id, listEditData)
            .then((res) => {
              toast.success("Berhasil Delete Sales Area");
              getId();
              getTeamRegionDetailId();
            })
            .catch((e) => {
              items.disableButton = false;
              toast.error(e.response.data.detail)
              getId();
              // edit.members.splice(edit.members.indexOf(valMember), 1);
              getTeamRegionDetailId();
            });
    };

    function showModalDelete(val1, val2){
      dataDelete.salesId= val1;
      dataDelete.salesName = val2;
    };

    function showModalDeleteArea(val){
      dataDelete.province = val;
    };

    const getDetailTimAreaandKet = () => {
      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id)
        .then((res) => {
          edit.leaderId = res.data.leaderId;
          edit.leaderName = res.data.leaderName;
          edit.name = res.data.name;
          edit.note = res.data.note;
        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });
    };

    const getDetailFirstTime = () => {
      // console.log('get All');
      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + 
        '/detail?fromMonth='+mm+'&fromYear='+yyyy+
        '&untilMonth='+mm+'&untilYear='+yyyy+'&page=0&size=10&sort=salesName&dir=1')
        .then((res) => {
          edit.members = res.data.content;

          var jumlahSekolah = 0;
          var jumlahPendapatanTerbayar = 0;
          var jumlahPendapatanBelumTerbayar = 0;
          var jumlahPendapatan = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            jumlahSekolah += res.data.content[i].globalSchools;
            jumlahPendapatanTerbayar += res.data.content[i].invoicePaid;
            jumlahPendapatanBelumTerbayar += res.data.content[i].invoiceUnpaid;
            jumlahPendapatan += res.data.content[i].totalInvoices;
          }

          edit.totalSekolah = jumlahSekolah;
          edit.totalPendapatanTerbayar = jumlahPendapatanTerbayar;
          edit.totalPendapatanBelumTerbayar = jumlahPendapatanBelumTerbayar;
          edit.totalPendapatan = jumlahPendapatan;
        })
        .catch((e) => {
          edit.members = [];
          toast.error(e.response.data.detail)

        });
    };
    
    const getId = () => {
      // console.log('get All');
      
      if(edit.fromMonth == 0){
        var today = new Date();
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        edit.fromMonth = parseInt(mm);
        edit.fromYear = yyyy;
        edit.untilMonth = parseInt(mm);
        edit.untilYear = yyyy;
      }

      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + 
        '/detail?fromMonth='+edit.fromMonth+'&fromYear='+edit.fromYear+
        '&untilMonth='+edit.untilMonth+'&untilYear='+edit.untilYear+'&page=0&size=10&sort=salesName&dir=1')
        .then((res) => {
          edit.members = res.data.content;

          var jumlahSekolah = 0;
          var jumlahPendapatanTerbayar = 0;
          var jumlahPendapatanBelumTerbayar = 0;
          var jumlahPendapatan = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            jumlahSekolah += res.data.content[i].globalSchools;
            jumlahPendapatanTerbayar += res.data.content[i].invoicePaid;
            jumlahPendapatanBelumTerbayar += res.data.content[i].invoiceUnpaid;
            jumlahPendapatan += res.data.content[i].totalInvoices;
          }

          edit.totalSekolah = jumlahSekolah;
          edit.totalPendapatanTerbayar = jumlahPendapatanTerbayar;
          edit.totalPendapatanBelumTerbayar = jumlahPendapatanBelumTerbayar;
          edit.totalPendapatan = jumlahPendapatan;
        })
        .catch((e) => {
          edit.members = [];
          toast.error(e.response.data.detail)

        });
    };


    const getTeamRegionDetailId = () => {
      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + '/region')
        .then((res) => {
          edit.regions = res.data;
        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });
    };
    function formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    function pilihTanggalAll(){
      console.log(edit.dateAll)
      var dateFromArray = edit.dateAll[0].split("-");
      var dateUntilArray = edit.dateAll[1].split("-");

      edit.fromMonth = dateFromArray[1];
      edit.fromYear = dateFromArray[0];
      edit.untilMonth = dateUntilArray[1];
      edit.untilYear = dateUntilArray[0];

      getId();
    };

    function getCurrentMonth(){
      // console.log('current');
      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      edit.dateAll = [yyyy+'-'+mm,yyyy+'-'+mm];
    };

    function ShowFilter(){
      if(edit.showFilter == false){edit.showFilter = true}else{edit.showFilter=false}
    };

    function getProvinceFromArrayList(val){
      var newArrayString = new Array();
        for(var i = 0; i < val.length; i++){
            newArrayString.push(val[i].province);
        }

      return newArrayString;
    };

    function addSalesModal(){
      items.members = "";
    };

    function addAreaModal(){
      items.regions = "" ;
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_USER);
      getDetailTimAreaandKet();
      // getDetailFirstTime();
      getCurrentMonth();
      getId();
      getTeamRegionDetailId();
      combo_user_sales_area();
      getProvinsi();
    });

    return {
      submitButton,
      saveChanges1,
      edit,
      items,
      dataDelete,
      getDetailTimAreaandKet,
      getId,
      combo_user_sales_area,
      getProvinsi,
      submitAddProvince,
      submitAddSales,
      handleDeleteArea,
      handleDeleteSales,
      
      getCurrentMonth,
      getTeamRegionDetailId,
      formatPrice,
      pilihTanggalAll,
      // getDetailFirstTime,
      ShowFilter,

      getProvinceFromArrayList,

      addSalesModal,
      addAreaModal,
      showModalDelete,
      showModalDeleteArea,
    };
  },
});
